<template>
  <base-info-card title="Quick Links">
    <a
      v-for="feature in features"
      :key="feature.name"
      :text="feature.name"
      class="mb-8 grey--text body-1 d-block text-none"
      :href="feature.to"
      v-html="`&rsaquo; ${feature.name}`"
    />
  </base-info-card>
</template>

<script>
  export default {
    name: 'SectionFeaturesAlt',

    data: () => ({
      features: [
        { name: 'Home', to: '/' },
        { name: 'About', to: 'About' },
        { name: 'Products', to: '/Products' },
        { name: 'Apply', to: '/Apply' },
        // { name: 'Transportation Directory', to: 'TransportationDirectory' },
        // { name: 'Auto PD Directory', to: 'AutoPDDirectory' },
        { name: 'Portal', to: '/Portal' },
        { name: 'Contact Us', to: '/ContactUs' },
        { name: 'Privacy Policy', to: '/PrivacyPolicy' },
      ],
    }),
  }
</script>
